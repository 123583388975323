.StripeElement.StripeElement--empty,
.StripeElement.StripeElement--focus,
.StripeElement.StripeElement--invalid,
.StripeElement.StripeElement--complete {
    background: white;
    border-radius: 5px;
    padding: 16px;
    margin-bottom: 16px;
    border: 1px solid #e2e8f0;
}

.StripeElement.StripeElement--focus,
.StripeElement.StripeElement--complete {
    border-color: orange
}

.StripeElement.StripeElement--invalid {
    border-color: red;
    box-shadow: 0 0 0 0.5px red;
}

.expiryStripe {
    /* margin-right: 10px; */
    flex: 1
}

.cvcStripe {
    flex: 1;
}